<template>
  <section ref="main">
    <section class="header" id="s1">
      <section :class="`nav ${data.className}`">
        <div :class="`nav_container ${language ? '' : 'en'}`">
          <img class="nav_logo" src="@/assets/logo.svg" alt="" />
          <ul>
            <li @click="handleNav('s1')">
              <router-link to="/?anchor=s1">{{ $t("nav.index") }}</router-link>
              <div></div>
            </li>
            <li @click="handleNav('s2')">
              <router-link to="/?anchor=s2">{{ $t("nav.about") }}</router-link>
              <div></div>
            </li>
            <li @click="handleNav('s3')">
              <router-link to="/?anchor=s3">{{ $t("nav.business") }}</router-link>
              <div></div>
            </li>
            <li @click="handleNav('s4')">
              <router-link to="/?anchor=s4">{{ $t("nav.features") }}</router-link>
              <div></div>
            </li>
            <li @click="handleNav('s5')">
              <router-link to="/?anchor=s5">{{ $t("nav.service") }}</router-link>
              <div></div>
            </li>
            <li @click="handleNav('s6')">
              <router-link to="/?anchor=s6">{{ $t("nav.contact") }}</router-link>
              <div></div>
            </li>
            <li class="active">
              <router-link to="/projects">{{ $t("nav.project") }}</router-link>
              <div></div>
            </li>
            <li>
              <span
                :class="language ? 'languageActive' : ''"
                @click="handleChangeLanguage('zh-CN')"
                >中文</span
              >
              /
              <span
                :class="language ? '' : 'languageActive'"
                @click="handleChangeLanguage('en-US')"
                >EN</span
              >
            </li>
          </ul>
          <img
            class="nav_menu"
            src="@/assets/mobile/icon_menu.png"
            alt=""
            @click="handleShowMenu"
          />
        </div>
      </section>
      <div :class="`header_container ${language ? '' : 'en'}`">
        <h3>{{ $t('projects.title') }}</h3>
        <div class="project">
          <div v-for="item in data.icons" :key="item.name">
            <img :src="item.img" alt="" class="cover">
            <div class="describe">
              <a :href="item.href" target="_blank"><img :src="item.logo" alt="" class="logo"></a>
              <h3>{{ $t(`projects.${item.name}.name`) }}</h3>
              <p>{{ $t(`projects.${item.name}.desc`) }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
  <section
    class="menu"
    :class="{ shake: data.visiable, hide: !data.visiable, en: !language }"
    @click="handleShowMenu"
    @touchmove.prevent
    @mousewheel.prevent
  >
    <div @click.stop>
      <img
        src="@/assets/mobile/icon_close.png"
        class="menu_close"
        @click="handleShowMenu"
        alt=""
      />
      <ul>
        <li @click="handleNav('s1')">
          <router-link to="/?anchor=s1">{{ $t("nav.index") }}</router-link>
          <div></div>
        </li>
        <li @click="handleNav('s2')">
          <router-link to="/?anchor=s2">{{ $t("nav.about") }}</router-link>
          <div></div>
        </li>
        <li @click="handleNav('s3')">
          <router-link to="/?anchor=s3">{{ $t("nav.business") }}</router-link>
          <div></div>
        </li>
        <li @click="handleNav('s4')">
          <router-link to="/?anchor=s4">{{ $t("nav.features") }}</router-link>
          <div></div>
        </li>
        <li @click="handleNav('s5')">
          <router-link to="/?anchor=s5">{{ $t("nav.service") }}</router-link>
          <div></div>
        </li>
        <li @click="handleNav('s6')">
          <router-link to="/?anchor=s6">{{ $t("nav.contact") }}</router-link>
          <div></div>
        </li>
        <li class="active projects">
          <router-link to="/projects">{{ $t("nav.project") }}</router-link>
          <div></div>
        </li>
        <li>
          <span
            :class="language ? 'languageActive' : ''"
            @click="handleChangeLanguage('zh-CN')"
            >中文</span
          >
          /
          <span
            :class="language ? '' : 'languageActive'"
            @click="handleChangeLanguage('en-US')"
            >EN</span
          >
        </li>
      </ul>
    </div>
  </section>
  <section
    class="fixed_input"
    v-show="data.isMail"
    :class="{ open: data.isFixedForm }"
  >
    <div class="fixed_input_container">
      <img
        src="@/assets/mail.svg"
        class="mail_pc"
        @click="handleMail"
        alt=""
        v-show="!data.isFixedForm"
      />
      <img
        src="@/assets/mail.svg"
        class="mail_m"
        @click="handleMobileMail"
        alt=""
        v-show="!data.isFixedForm"
      />
      <div
        class="fixed_form"
        v-show="data.isFixedForm"
        :class="{ form_show: data.isFixedForm }"
      >
        <input
          :placeholder="placeholder"
          id="focusinput"
          autofocus
          ref="input"
          @blur="handleBlur"
        />
        <button @click.stop="handleSendMail">
          {{ t("form.button") }} <img src="@/assets/icon_arrow.svg" alt="" />
        </button>
      </div>
    </div>
  </section>
  <div class="fixed_input2" v-show="data.isV">
    <div class="fixed_form">
      <input
        :placeholder="placeholder"
        id="focusinput"
        ref="input2"
        @blur="handleBlur"
      />
      <button @click.stop="handleSendMail">
        {{ t("form.button") }} <img src="@/assets/icon_arrow.svg" alt="" />
      </button>
    </div>
  </div>
</template>
<script>
import { ref, reactive, computed, onMounted, onUnmounted, nextTick } from "vue";
import { useI18n } from "vue-i18n";
export default {
  name: "App",
  setup() {
    // 基础相应数据集
    const data = reactive({
      language: window.localStorage.getItem("locale") || "en-US",
      className: "",
      scrollPosition: "s1",
      visiable: false,
      icons: [
        {
          name: 'forkearth',
          img: require("@/assets/earth.jpg"),
          logo: require("@/assets/fork-earth_logo.svg"),
          href: 'http://www.forkearth.io'
        },
        {
          name: 'larvaverse',
          img: require("@/assets/larva.jpg"),
          logo: require("@/assets/larvaverse_logo.svg"),
          href: 'http://www.larvaverse.io'
        }
      ],
      businessIcons: [],
      featuresIcons: [],
      isFixedForm: false,
      isV: false,
      isMail: true,
    });
    // i18n 对应方法
    const { t, locale } = useI18n({ useScope: "global" });
    // 切换语言
    const handleChangeLanguage = (language) => {
      locale.value = language;
      data.language = language;
      window.localStorage.setItem("locale", language)
    };
    // 获取语言状态
    const language = computed(() => data.language === "zh-CN");
    // 根据不同语言变更css样式
    const enClassName = computed(() => {
      return language.value ? "" : "en";
    });
    // 点击导航切换导航选中状态
    const handleNav = (val) => {
      console.log(val)
    };
    // 监测滚动条滚动距离并变更导航状态
    /* eslint-disable */
    const handleScroll = () => {
      // data.isFixedForm = false
      let scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
      
      let winWidth = document.body.clientWidth
      // 设置导航背景颜色
      if (winWidth <= 768) {
        if (scrollTop > 0) data.className = 'nav_bg'
        else data.className = ''
      } else {
        if (scrollTop > 30) data.className = 'nav_bg'
        else data.className = ''
      } 
    }
    const placeholder = computed(() => {
      let winWidth = document.body.clientWidth
      if (!language.value && 1000 > winWidth) {
        return 'your email address'
      } else {
        return t("form.input")
      }
    })
    
    const input = ref(null)
    const handleMail = () => {
      data.isFixedForm = true
      nextTick(() => {
        input.value.focus()
        input.value.scrollIntoView(true)
      })
    }
    const handleBlur = () => {
      // 失去焦点时取消显示
      setTimeout(() => {
      data.isFixedForm = false
      data.isV = false
      }, 100)

    }

    const handleShowMenu = () => {
      data.visiable = !data.visiable
    }

    const touchScroll = () => {
      nextTick(() => {
        if (input) input.value.blur()
        if (input) input2.value.blur()
      })
    }

    const main = ref(null)

    // 生命周期相关内容
    onMounted(() => {
      window.addEventListener("scroll", handleScroll);
      if (main && main.value) main.value.addEventListener("touchend", touchScroll);
    });
    onUnmounted(() => {
      nextTick(() => {
        window.removeEventListener('scroll', handleScroll)
        if (main && main.value) main.value.removeEventListener("touchend", touchScroll);
      })
    });
    const input2 = ref(null)
    const handleMobileMail = () => {
      data.isV = true
      nextTick(() => {
        input2.value.focus()
      })
    }

    const handleSendMail = () => {
      // console.log('Send Mail')
    }
    return {
      handleChangeLanguage,
      t,
      language,
      data,
      enClassName,
      handleNav,
      handleShowMenu,
      placeholder,
      input,
      handleBlur,
      handleMail,
      main,
      handleMobileMail,
      input2,
      handleSendMail
    };
  },
};
</script>

<style lang="less" scoped>
.activeMenu {
  height: 100vh;
  overflow: hidden;
}
.nav {
  width: 100%;
  height: 70px;
  color: #fff;
  position: absolute;
  top: 30px;
  .nav_logo {
    width: 160px;
    height: 40px;
  }
  &_container {
    width: 1024px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    &.en {
      ul > li {
        a {
          font-family: Akrobat-Light;
          font-size: 16px;
          letter-spacing: 0;
          text-align: center;
          line-height: 16px;
          font-weight: 400;
        }
        &.active {
          a {
            font-family: Akrobat-Black;
          }
        }
      }
    }
  }
  ul {
    flex: 1;
    display: flex;
    justify-content: space-between;
    list-style: none;
    margin-left: 200px;
    font-size: 14px;
    a {
      color: #fff;
      text-decoration: none;
      font-weight: 200;
    }
    span {
      cursor: pointer;
    }
    div {
      width: 28px;
      height: 4px;
      background: #84F4F9;
      border-radius: 2px;
      margin: 0 auto;
      margin-top: 8px;
      opacity: 0;
    }
    li.active {
      a {
        color: #84F4F9;
      }
      div {
        opacity: 1
      }
    }
  }
  &_menu {
    display: none;
  }
}
.nav_bg {
  background: rgba(15,3,52,0.70);
  box-shadow: 0 2px 8px 0 rgba(0,0,0,0.70);
  margin-top: 0;
  position: fixed;
  top: 0;
}
.header {
  width: 100%;
  background: #0F0334;
  // background-size: auto 100%;
  // height: 570px;
  padding-top: 70px;
  &_container {
    padding-bottom: 80px;
    & > h3 {
      color: #655E72;
      line-height: 42px;
      font-weight: 900;
      font-size: 36px;
      width: 1020px;
      margin: 0 auto;
      padding-top: 80px;
    }
    .project {
      & > div {
        width: 1020px;
        height: 510px;
        display: flex;
        background: #655E72;
        margin: 0 auto;
        margin-top: 80px;
        .cover {
          width: 510px;
          height: 510px;
        }
        .describe {
          flex: 1;
          padding: 40px;
          color: #fff;
          .logo {
            height: 40px;
          }
          h3 {
            font-size: 24px;
            font-weight: 600;
            color: #FFFFFF;
            line-height: 28px;
            margin: 40px 0 20px 0;
          }
          p {
            font-size: 16px;
            font-weight: 400;
            color: #F5F5F4;
            line-height: 24px;
          }
        }
        &:last-child {
          flex-direction: row-reverse;
        }
      }
    }
    &.en {
      & > h3 {
        font-size: 36px;
        font-family: Akrobat-Black;
      }
      & > div {
        .describe {
          h3 {
            font-size: 28px;
            font-family: Akrobat-Black;
            font-weight: 900;
            color: #F5F5F4;
            line-height: 28px;
          }
          p {
            font-size: 21px;
            font-family: Akrobat-Bold;
            font-weight: 800;
            color: #F5F5F4;
            line-height: 28px;
          }
        }
      }
    }
  }
}
.fixed_form {
  width: 400px;
  height: 50px;
  background: #fff;
  border-radius: 25px;
  display: flex;
  align-items: center;
  padding-left: 24px;
  input {
    border: 0;
    flex: 1;
    &:focus {
      outline: none;
      border: 0px solid #fff;
    }
    &::-webkit-input-placeholder {
      color: #BAB8B1;
      font-size: 16px;
      letter-spacing: 0;
      // line-height: normal;
      font-weight: 400;
    }
    &::-moz-placeholder {
      color: #bab8b1;
      font-size: 16px;
      letter-spacing: 0;
      // line-height: normal;
      font-weight: 400;
    }
    &:-moz-placeholder {
      color: #bab8b1;
      font-size: 16px;
      letter-spacing: 0;
      // line-height: normal;
      font-weight: 400;
    }
    &:-ms-input-placeholder {
      color: #bab8b1;
      font-size: 16px;
      letter-spacing: 0;
      // line-height: normal;
      font-weight: 400;
    }
  }

  button {
    border: 0;
    width: 90px;
    height: 38px;
    background: #f36b32;
    border-radius: 19px;
    display: flex;
    align-items: center;
    margin-right: 6px;
    justify-content: flex-end;
    cursor: pointer;

    font-size: 16px;
    color: #F5F5F4;
    letter-spacing: 0;
    line-height: 24px;
    font-weight: 600;
    img {
      margin-left: 8px;
      margin-right: 3px;
      width: 22px;
      height: 22px;
    }
  }
}
.en {
  .fixed_form {
    input {
      &::-webkit-input-placeholder {
        font-family: Akrobat-Bold;
      }
      &::-moz-placeholder {
        font-family: Akrobat-Bold;
      }
      &:-moz-placeholder {
        font-family: Akrobat-Bold;
      }
      &:-ms-input-placeholder {
        font-family: Akrobat-Bold;
      }
    }
    button {
      font-family: Akrobat-Bold;
    }
  }
}
.languageActive {
  color: #84F4F9;
  font-size: 14px;
  color: #84F4F9;
  letter-spacing: 0;
  text-align: center;
  line-height: 14px;
  font-weight: bold;
}
.menu {
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.50);
  position: fixed;
  top: 0;
  right: 0;
  visibility:hidden;
  opacity:0;
  transform:translateY(0px);
  transition:.3s;
  z-index: 2;
  & > div {
    width: 64vw;
    height: 100vh;
    background: #1D0568;
    position: absolute;
    top: 0;
    right: 0;
    ul {
      list-style: none;
      color: #fff;
      margin-top: 24vw;
      li {
        margin-bottom: 5vw;
        a {
          color: #F5F5F4;
          text-decoration: none;
          display: inline-block;
          font-size: 3.73vw;
          & > div {
            height: 1.3vw;
            border-radius: .65vw;
            background: none;
            width: 100%;
            // display: inline-block;
          }
        }
        span {
          font-size: 3.73vw;
        }
        &.active {
          a {
            color: #84F4F9;
            div {
              background: #84F4F9;
            }
          }
        }
        &.projects {
          div {
            width: 50px;
            height: 1.3vw;
            border-radius: .65vw;
            background: none;
            width: 50%;
            margin-left: 12%;
            background: #84F4F9;
          }
        }
      }
    }
  }
  &_close {
    width: 4.2vw;
    margin-left: 55.7vw;
    margin-top: 3.2vw;
  }
  &.en {
    
    ul li {
      a {
        font-size: 4.8vw;
        font-family: Akrobat-Bold;
        & > div {
          width: 60%;
          margin: 0 auto;
        }
      }
      &.active {
        a {
          color: #84F4F9;
          font-family: Akrobat-Black;
          & > div {
            background: #84F4F9;
          }
        }
      }
    }
  }
}
.shake {
  visibility:visible;
  opacity:1;
  transform:translateY(0);
}
.fixed_input {
  position: fixed;
  right: 10px;
  bottom: 50px;
  height: 80px;
  min-width: 80px;
  background: #60AEFF;
  box-shadow: 0px 8px 8px 0px rgba(15, 3, 52, 0.3);
  border-radius: 40px;

  &_container {
    width: 100%;
    height: 100%;
    border-radius: 40px;
    box-shadow: 0px 0px 20px rgba(131, 244, 249, 0.8) inset;
    display: flex;
    align-items: center;
    justify-content: center;
    & > img {
      width: 40px;
      height: 28px;
      cursor: pointer;
      &.mail_pc {
        display: block;
      }
      &.mail_m {
        display: none;
      }
    }
    .fixed_form {
      opacity: 0;
      margin: 0 20px;
    }
  }
}
@keyframes aslide {
   0% {
    width: 80px;
   }
   100% {
    width: 464px;
   }
}
.open {
  animation: aslide .5s;
  animation-fill-mode: forwards;
}
@keyframes aslide1 {
   0% {
    opacity: 0;
   }
   100% {
    opacity: 1;
   }
}
.form_show {
  animation: aslide1 .1s;
  animation-fill-mode: forwards;
  animation-delay: .25s;
}

.fixed_input2 {
  position: fixed;
  bottom: 5vw;
  width: 84vw;
  right: 8vw;
  z-index: 3;
  .fixed_form {
    width: 80vw;
    padding-left: 4vw;
  }
  input {
    width: 100%;
    border: 1px solid #000;
    margin: 0;
    padding: 0;
  }
}

@media (max-width: 768px) {
  html,body {
    overflow-x: hidden;
  }
  .nav {
    height: 10.6vw;
    background: #0F0334;
    top: 0;
    .nav_logo {
      width: 26.6vw;
    }
    ul {
      display: none;
    }
    &_container {
      justify-content: space-between;
      width: 92%;
      padding: 0 4%;
    }
    &_menu {
      display: block;
      width: 4.8vw;
    }
  }
  .header {
    background: #0F0334;
    background-size: 100% auto;
    height: auto;
    padding-bottom: 15vw;
    &_container {
      padding: 0 8vw;
      & > h3 {
        width: 100%;
        padding-top: 1vw;
        font-size: 6.4vw;
      }
      .project {
        width: 100%;
        & > div {
          width: 100%;
          height: auto;
          flex-direction: column;
          margin-top: 8vw;
          .cover {
            width: 100%;
            height: 84vw;
            // height: 510px;
          }
          .describe {
            padding: 5.33vw;
            .logo {
              height: 10.6vw;
            }
            h3 {
              font-size: 4.8vw;
              margin: 8vw 0 5.4vw 0;
            }
            p {
              font-size: 3.73vw;
            }
          }
          &:last-child {
            flex-direction: column;
          }
        }
      }
      &.en {
        & > h3 {
          font-size: 9.6vw;
        }
        .project {
          & > div {
            .describe {
              h3 {
                font-size: 7.4vw;
              }
              p {
                font-size: 4.8vw;
              }
            }
          }
        }
      }
    }
  }
  .fixed_form {
    width: 84%;
    height: 13.3vw;
    margin-top: 12vw;
    align-items: center;
    border-radius: 7vw;
    input {
      height: 3.73vw;
      font-size: 3vw;
      line-height: normal;
      &::-webkit-input-placeholder {
        font-size: 3.73vw;
        transform: translate(0, 11%);
        -webkit-transform: translate(0, 11%);
      }
      &::-moz-placeholder {
        font-size: 3.73vw;
        transform: translate(0, 11%);
        -webkit-transform: translate(0, 11%);
      }
      &:-moz-placeholder {
        font-size: 3.73vw;
        transform: translate(0, 11%);
        -webkit-transform: translate(0, 11%);
      }
      &:-ms-input-placeholder {
        font-size: 3.73vw;
        transform: translate(0, 11%);
        -webkit-transform: translate(0, 11%);
      }
    }
    button {
      font-size: 3.73vw;
      width: 22vw;
      height: 10vw;
      border-radius: 5vw;
      padding: 0;
      line-height: normal;
      img {
        width: 7.46vw;
      }
    }
  }
  .fixed_input {
    min-width: 13.3vw;
    height: 13.3vw;
    bottom: 5.3vw;
    right: 8vw;
    z-index: 1;
    &_container {
      padding: 0;
      margin: 0;
      & > img {
        width: 6.93vw;
        height: 4.8vw;
        &.mail_pc {
          display: none;
        }
        &.mail_m {
          display: block;
        }
      }
      .fixed_form {
        width: 100%;
        margin: 0;
        padding: 0;
        input {
          margin-left: 3vw;
        }
      }
    }
  }
  @keyframes aslide {
    0% {
      width: 6.93vw;
    }
    100% {
      width: 84vw;
    }
  }
  .open {
    animation: aslide .5s;
    animation-fill-mode: forwards;
    width: 84vw;
  }
}
</style>
